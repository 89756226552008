<template>
  <div>
    <div class="leftcontHead">
      <div class="boxs">
        <div
          class="box"
          :class="index == typeOn ? 'on' : ''"
          v-for="(item, index) in typeList"
        >
          <div class="name">{{ item }}</div>
        </div>
      </div>
      <div class="inpBox">
        <input type="text" placeholder="请搜索您发布的内容" />
        <div class="ssBox iconfont icon-search"></div>
      </div>
    </div>
    <div class="content">
      <div class="contType">
        <div class="txt w20">评分</div>
        <div class="txt w40">评论内容</div>
        <div class="txt w20">被评产品</div>
        <div class="txt w20">操作</div>
      </div>
      <div class="contList">
        <Kong v-if="list.length == 0" />
        <div class="contLi" v-for="(item, index) in list">
          <div class="txt w20">
            <div class="iconfont icon-collection_fill collection_fill" v-for="chr in item.score"></div>
          </div>
          <div class="txt w40">{{ item.content }}</div>
          <div class="txt w20">{{ item.partname }}</div>
          <div class="txt w20">
            <a-popconfirm
              title="是否确定删除此评论"
              ok-text="是"
              cancel-text="否"
              @confirm="delConfirm(item, index)"
            >
              <div class="text">删除</div>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kong from "../component/kong.vue";
export default {
  components: {
    Kong,
  },
  name: "",
  data() {
    return {
      typeOn: 0,
      typeList: ["评论列表"],
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 35,
      },
      list: [],
    };
  },
  created() {
    this.listGet();
  },
  methods: {
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/evaluation_member", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    delConfirm(e, i) {
      this.$post("/web/delete", {
        table: "order_evaluation",
        id: e.id,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.$message.success("删除成功");
          this.page.pageNo = 1;
          this.list = [];
          this.listGet();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    ltjump() {
      this.$router.push({
        path: "/wzCont",
        query: {},
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.leftcontHead {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 37px;
  background-color: #fff;
  border-radius: 5px;
  .boxs {
    display: flex;
    align-items: center;
    line-height: 60px;
  }
  .box {
    margin-right: 44px;
    height: 58px;
    cursor: pointer;

    .name {
      font-size: 16px;
    }
  }

  .on {
    border-bottom: 3px solid #f66;

    // transition: all .3s linear;
    .name {
      font-weight: 700;
    }
  }
  .inpBox {
    width: 220px;
    border-radius: 35px;
    height: 35px;
    border: 1px solid #f66;
    position: relative;
    color: #f66;
    overflow: hidden;
    margin-right: 15px;
    input {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 15px;
      color: #f66;
    }
    .ssBox {
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      line-height: 35px;
      font-size: 18px;
      position: absolute;
      cursor: pointer;
    }
  }
}
.content {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  .contType {
    height: 50px;
    background-color: #f3f6f8;
    margin-top: 10px;
    display: flex;
    box-sizing: border-box;
    .txt {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
  }
  .contList {
    background-color: #fff;
    .contLi {
      height: 50px;
      display: flex;
      box-sizing: border-box;
      border-bottom: 1px solid #f3f3f3;
      .txt {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 12px;
        color: #333;
        .text {
          margin-right: 10px;
          cursor: pointer;
        }
        .red {
          color: #f32004;
        }
        .collection_fill {
          color: #f32004;
        }
      }
    }
    .contLi:last-child {
      border-bottom: none;
    }
  }
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}
</style>
